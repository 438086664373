body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.aGeoTunis{
  color: #002d72;
  text-decoration: none;
}
.aGeoTunis:hover{
  text-decoration: underline;
}

.h4{
  color: #77cce9;
}

.jumbotronGeotunis{
  background: url("../public/static/img/geotunis16/banniere-geotunis16.jpg") center no-repeat;
  background-size: cover;
  padding-top: 27%;
  position: relative;
  border-radius: 0;
}

.card-subtitle{
  color: #002d72;
}

.sem, .workshops, .courses, .interEx, .objectifs, .themes, .aboutActHeader{
  font-weight: bold;
}

.geo-btn-group{
  right: 0;
  bottom: 0;
  position: absolute;
}
.geotunis{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 2;
  color:#333;
  text-align: left;
}

.ArDirection{
  text-align: right;
  direction: rtl;
}


.axis, .app, .objectifs, .themes, .aboutActHeader{
  color: red;
}


.navlinkGeoTunis{
  color: #478ac9;
  font-size: 15px;
  font-family:Arial, Helvetica, sans-serif;
  font-weight: bold;
  cursor: pointer;
}

.nav-tabs{
  font-family: 'robotomedium';
}

.jumbotron h1 {
    font-family: 'robotoblack';
    font-size: 42px;
    color: #77cce9;
    letter-spacing: 2px;
}
.jumbotron h3 {
    font-family: 'robotobold';
    color: #ffc107;
    font-size: 32px;
}
.jumbotron p {
    font-family: 'robotobold';
    font-size: 28px;
    color: #fff;
    letter-spacing: 2px;
    margin: 0;
}

.sem, .workshops, .courses, .interEx, .objectifs, .themes, .aboutActHeader{
  font-weight: bold;
}

.footer-logo{
  width: 60px;
  height: auto; 
  border-radius: 20px;
}

.logo-container {
  display: inline-block;
  margin-right: 20px;
}