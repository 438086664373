.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.leaflet-container {
  height: 400px;
}

.App-content {
  flex-grow: 1;
}
.footer{
  background-color: #87cff061;
  padding: 20px 0;
  display: flex;
  bottom: 0;
  z-index: 2;
}


.sidebar-container {
  display: flex;
  height: 100vh;
  z-index: 1;
}

.chart-wrapper {
  width: 70%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jumbotron{
  background-color: #375f9c!important;
  margin: 0;
  padding: 0;
  width: 100%;
}

.tadgi{
  color: #fff!important;
  text-shadow: 0 0 4px #06a2d3;
}

.obj-container{
  background-image: url("../public/static/img/pexelsphoto118009.jpeg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
  text-shadow: 0 0 4px #06a2d3;
}

.button-separator {
  width: 2px; /* Adjust the width as needed */
  height: 100%; /* Match the height of the buttons */
  display: inline-block;
  background-color: #ccc;
}